
import { get_pdf_put_info, get_boardstate_put_info, get_boardstate_get_info, get_pdf_get_info } from "../api/filestore";
import { join_board, set_board_name, set_link_edit } from "../api/boards"
import session from "./session"
import { getPayload } from "./token";

class TemporaryUrl {
	public url: string;
	public expired: boolean;
	constructor(url: string, expiresMillis: number) {
		this.url = url;
		this.expired = expiresMillis <= 0;
		if (!this.expired) {
			setTimeout(() => {
				this.expired = true;
			}, expiresMillis);
		}
	}
}

class MultiPromise<T> {
	private _thing: T|null = null;
	private _error: Error|null = null;
	private _resolves: ((thing: T)=>void)[] = [];
	private _rejects: ((error: Error)=>void)[] = [];
	constructor(promise: Promise<T>) {
		this._await(promise);
	}
	private async _await(promise: Promise<T>): Promise<void> {
		try {
			this._thing = await promise;
			for (let resolve of this._resolves) {
				resolve(this._thing);
			}
		} catch (error) {
			this._error = error;
			for (let reject of this._rejects) {
				reject(error);
			}
		}
	}
	public promise(): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			if (this._thing) {
				resolve(this._thing);
			} else if (this._error) {
				reject(this._error);
			} else {
				this._resolves.push(resolve);
				this._rejects.push(reject);
			}
		});
	}
}

class Board {

	public isNew: boolean = false;
	
	private _name: string|null = null;
	private _anyoneWithLinkCanEdit: boolean|null = null;
	private _realtimeToken: string|null = null;

	// for managing boardToken
	private _boardTokenMultiPromise: MultiPromise<string|null>|null = null;

	private _putUrlMultiPromise: MultiPromise<string|null>|null = null;
	private _getUrlMultiPromise: MultiPromise<string|null>|null = null;
	private _pdfGetUrlMultiPromises: { [pdfUrlId: string]: MultiPromise<string|null> } = {};

	// urls for file storage/retrieval
	private _putUrl: TemporaryUrl = new TemporaryUrl("", -1);
	private _getUrl: TemporaryUrl = new TemporaryUrl("", -1);
	private _pdfGetUrls: { [pdfUrlId: string]: TemporaryUrl } = {};

	constructor(private _boardUrlId: string) {

	}

	public async allowViewing(): Promise<boolean> {
		const boardToken = await this._getBoardToken();
		if (boardToken) {
			return await this._view(boardToken);
		} else {
			return false;
		}
	}

	public async allowRenaming(): Promise<boolean> {
		const boardToken = await this._getBoardToken();
		return await this._admin(boardToken);
	}

	public async getName(): Promise<string> {
		await this._getBoardToken(); // once the board token is available, the name should be set
		return this._name;
	}

	public async getAnyoneWithLinkCanEdit(): Promise<boolean> {
		await this._getBoardToken(); // once the board token is available, the name should be set
		return this._anyoneWithLinkCanEdit;
	}

	public async getRealtimeToken(): Promise<string> {
		await this._getBoardToken(); // once the board token is available, the realtimeToken should be set
		return this._realtimeToken;
	}

	public async setName(name: string): Promise<boolean> {
		const boardToken = await this._getBoardToken();
		if (this._admin(boardToken)) {
			const response = await set_board_name(boardToken, name);
			if (session.user) {
				session.user.onBoardRenamed(this._boardUrlId, name);
			}
			return response.success;
		}
		return false;
	}

	public async setAnyoneWithLinkCanEdit(edit: boolean): Promise<boolean> {
		const boardToken = await this._getBoardToken();
		if (this._admin(boardToken)) {
			const response = await set_link_edit(boardToken, edit);
			if (response.success) {
				this._anyoneWithLinkCanEdit = edit;
			}
			return response.success;
		}
		return false;
	}

	public async getUrl(): Promise<string|null> {
		// if cached url not expired, return that
		if (!this._getUrl.expired) return this._getUrl.url;
		try {
			// if a request is in flight, return the result from that
			if (this._getUrlMultiPromise) return await this._getUrlMultiPromise.promise();
			// start a request
			this._getUrlMultiPromise = new MultiPromise<string|null>(
				(async () => {
					const boardToken = await this._getBoardToken();
					// at this point, it's possible the getUrl has been set, so check for that
					if (!this._getUrl.expired) {
						return this._getUrl.url;
					}
					const response = await get_boardstate_get_info(boardToken);
					this._getUrlMultiPromise = null;
					if (response.success) {
						this._getUrl = new TemporaryUrl(response.result.getUrl, response.result.expiresMillis);
						return response.result.getUrl;
					} else {
						return null;
					}
				})()
			);
			return await this._getUrlMultiPromise.promise();
		} catch(error) {
			return null;
		}
	}

	public async putUrl(): Promise<string|null> {
		// if cached url not expired, return that
		if (!this._putUrl.expired) return this._putUrl.url;
		try {
			// if a request is in flight, return the result from that
			if (this._putUrlMultiPromise) return await this._putUrlMultiPromise.promise();
			// start a request
			this._putUrlMultiPromise = new MultiPromise<string|null>(
				(async () => {
					const boardToken = await this._getBoardToken();
					// at this point, it's possible the putUrl has been set, so check for that
					if (!this._putUrl.expired) {
						return this._putUrl.url;
					}
					const response = await get_boardstate_put_info(boardToken);
					this._putUrlMultiPromise = null;
					if (response.success) {
						this._putUrl = new TemporaryUrl(response.result.putUrl, response.result.expiresMillis);
						return response.result.putUrl;
					} else {
						return null;
					}
				})()
			);
			return await this._putUrlMultiPromise.promise();
		} catch(error) {
			return null;
		}
	}

	public async pdfGetUrl(pdfUrlId: string): Promise<string|null> {
		// if cached url not expired, return that
		if (pdfUrlId in this._pdfGetUrls && !this._pdfGetUrls[pdfUrlId].expired) return this._pdfGetUrls[pdfUrlId].url;
		try {
			// if a request is in flight, return the result from that
			if (pdfUrlId in this._pdfGetUrlMultiPromises) return await this._pdfGetUrlMultiPromises[pdfUrlId].promise();
			// start a request
			this._pdfGetUrlMultiPromises[pdfUrlId] = new MultiPromise<string|null>(
				(async () => {
					const boardToken = await this._getBoardToken();
					const response = await get_pdf_get_info(boardToken, pdfUrlId);
					delete this._pdfGetUrlMultiPromises[pdfUrlId];
					if (response.success) {
						this._pdfGetUrls[pdfUrlId] = new TemporaryUrl(response.result.getUrl, response.result.expiresMillis);
						return response.result.getUrl;
					} else {
						return null;
					}
				})()
			);
			return await this._pdfGetUrlMultiPromises[pdfUrlId].promise();
		} catch(error) {
			return null;
		}
	}

	public async pdfPutUrl(): Promise<{putUrl:string, urlId:string}|null> {
		// each time this is called, it's for a new pdf, so no need to worry about caching
		const boardToken = await this._getBoardToken();
		const response = await get_pdf_put_info(boardToken);
		if (response.success) {
			// cache the getUrl as it will be needed later
			const pdfUrlId = response.result.urlId;
			this._pdfGetUrls[pdfUrlId] = new TemporaryUrl(response.result.getUrl, response.result.expiresMillis);
			return {
				putUrl: response.result.putUrl,
				urlId: response.result.urlId,
			}
		}
		return null;
	}

	private async _getBoardToken(): Promise<string|null> {
		if (!this._boardTokenMultiPromise) {
			this._boardTokenMultiPromise = new MultiPromise<string|null>(
				(async () => {
					const userToken = session.user ? session.user.token : "";
					const response = await join_board(userToken, this._boardUrlId);
					if (response.success) {
						this._getUrl = new TemporaryUrl(response.result.stateFile.getUrl, response.result.stateFile.expiresMillis);
						if (response.result.stateFile.putUrl) {
							this._putUrl = new TemporaryUrl(response.result.stateFile.putUrl, response.result.stateFile.expiresMillis);
						}
						this._name = response.result.name;
						this._anyoneWithLinkCanEdit = response.result.anyoneWithLinkCanEdit;
						this._realtimeToken = response.result.realtimeToken;
						return response.result.boardToken;
					} else {
						return null;
					}
				})()
			);
		}
		return this._boardTokenMultiPromise.promise();
	}

	private _view(boardToken: string): boolean {
		if (this._edit(boardToken)) return true;
		const payload = getPayload(boardToken);
		return "permissions" in payload && "view" in payload.permissions && payload.permissions.view !== "none";
	}

	private _edit(boardToken: string): boolean {
		if (this._admin(boardToken)) return true;
		const payload = getPayload(boardToken);
		return "permissions" in payload && "edit" in payload.permissions && payload.permissions.edit !== "none";
	}

	private _admin(boardToken: string): boolean {
		const payload = getPayload(boardToken);
		return "permissions" in payload && "admin" in payload.permissions && payload.permissions.admin !== "none";
	}

}

class Boards {

	private _boards: { [boardUrlId: string]: Board } = {};

	public newBoard(boardUrlId: string, isCopy: boolean = false) {
		const board = new Board(boardUrlId);
		if (!isCopy) {
			board.isNew = true;
		}
		this._boards[boardUrlId] = board;
	}

	public getBoard(boardUrlId: string) {
		if (!(boardUrlId in this._boards)) {
			this._boards[boardUrlId] = new Board(boardUrlId);
		}
		return this._boards[boardUrlId];
	}

}

export default new Boards();