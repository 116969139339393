
import { getPayload } from "./token"
import serverBoardsInterface from "./boards"

import {
	BoardInfo,
	get_boards,
	add_board,
	copy_board,
	remove_board,
} from "../api/boards"

import {
	ContactInfo,
	get_contacts,
	add_contact,
	update_contact,
	remove_contact,
} from "../api/contacts"

export default class User {

	public get name() { return this._name; }
	public get email() { return this._email; }
	public get confirmed() { return this._confirmed; }
	public get token() { return this._token; }
	public get id() { return this._id; }

	private _name: string;
	private _email: string;
	private _confirmed: string;
	private _token: string;
	private _id: string;

	private _boards: { [key: string]: BoardInfo } | null = null;
	private _contacts: { [key: string]: ContactInfo } | null = null;

	constructor(token: string) {
		this._token = token;
		const payload = getPayload(token);
		this._name = payload.firstname;
		this._email = payload.email;
		this._confirmed = payload.confirmed;
		this._id = payload.id;
	}

	public updateToken(token: string) {
		const payload = getPayload(token);
		this._name = payload.firstname;
		this._email = payload.email;
		this._confirmed = payload.confirmed;
	}

	////////////
	// boards //
	////////////

	// this is hacky -- board info should really not belong to user anymore
	public onBoardRenamed(boardUrlId: string, name: string) {
		if (this._boards) {
			if (boardUrlId in this._boards) {
				this._boards[boardUrlId].name = name;
			}
		}
	}

	public async getBoards(): Promise<BoardInfo[]> {
		if (!this._boards) {
			const response = await get_boards(this._token);
			if (response.success) {
				this._boards = response.result.reduce((map, board) => {
					map[board.urlId] = board;
					return map;
				}, {});
			} else {
				this._boards = {};
			}
		}
		return Object.values(this._boards);
	}

	public async addBoard(): Promise<BoardInfo|null> {
		const response = await add_board(this._token);
		if (response.success) {
			const boardUrlId = response.result.urlId;
			if (this._boards) {
				// user navigated to /board (to create a new board) before this.getBoards() was called (when navigating to /account/boards)
				this._boards[boardUrlId] = response.result;
			}
			serverBoardsInterface.newBoard(boardUrlId, /*isCopy=*/false);
			return response.result;
		}
		return null;
	}

	public async copyBoard(boardUrlId: string): Promise<BoardInfo|null> {
		const response = await copy_board(this._token, boardUrlId);
		if (response.success) {
			const boardUrlId = response.result.urlId;
			if (this._boards) {
				// user navigated to /board (to create a new board) before this.getBoards() was called (when navigating to /account/boards)
				this._boards[boardUrlId] = response.result;
			}
			serverBoardsInterface.newBoard(boardUrlId, /*isCopy=*/true);
			return response.result;
		}
		return null;
	}

	public async removeBoard(board: BoardInfo): Promise<boolean> {
		const response = await remove_board(this._token, board);
		if (response.success) {
			if (this._boards) {
				delete this._boards[board.urlId];
			}
		}
		return response.success;
	}

	//////////////
	// contacts //
	//////////////
	
	public async getContacts(): Promise<ContactInfo[]> {
		if (!this._contacts) {
			const response = await get_contacts(this._token);
			if (response.success) {
				this._contacts = response.result.reduce((map, contact) => {
					map[contact.id] = contact;
					return map;
				}, {});
			} else {
				this._contacts = {};
			}
		}
		return Object.values(this._contacts);
	}

	public async addContact(name: string, email: string, sendInvite: boolean): Promise<boolean> {
		const response = await add_contact(this._token, name, email, sendInvite);
		if (response.success) {
			const contact = response.result;
			if (this._contacts) {
				this._contacts[contact.id] = contact;
			}
		}
		return response.success;
	}

	public async updateContact(contact: ContactInfo): Promise<boolean> {
		const response = await update_contact(this._token, contact);
		if (response.success) {
			const contact = response.result;
			if (this._contacts) {
				this._contacts[contact.id] = contact;
			}
		}
		return response.success;
	}

	public async removeContact(contact: ContactInfo): Promise<boolean> {
		const response = await remove_contact(this._token, contact);
		if (response.success) {
			if (this._contacts) {
				delete this._contacts[contact.id];
			}
		}
		return response.success;
	}

}