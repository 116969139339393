
/*
	The purpose of this file is to define all routes used by links and navigate().
	There should be no raw strings referencing site routes outside of this file, that is
		instead, of
			navigate("/privacy_policy") or <Link to="/privacy_policy" />
		use
			navigate(routes.privacy_policy) or <Link to={routes.privacy_policy} />
	This makes it easy to update the url for a particular route without needing to find all the references scattered throughout the source code.
		This is particularly useful when moving routes out of /staging/
*/

import { stagingPrefix } from "./environment"

// use this only for staging routes (over time, this should dwindle to nothing)
const staging = {
	/*
	account: {
		boards: `${stagingPrefix}/account/boards`,
		contacts: `${stagingPrefix}/account/contacts`,
		feedback: `${stagingPrefix}/account/feedback`,
		index: `${stagingPrefix}/account`,
		settings: `${stagingPrefix}/account/settings`,
	},
	*/
	// new_board: `${stagingPrefix}/board`,
	// board: (id: string) => `${stagingPrefix}/board/${id}`,
	// confirm: `${stagingPrefix}/confirm`,
	// demo: `${stagingPrefix}/demo`,
	educators: `${stagingPrefix}/educators`,
	// forgot_password: `${stagingPrefix}/forgot_password`,
	// login: `${stagingPrefix}/login`,
	// logout: `${stagingPrefix}/logout`,
	// signup: `${stagingPrefix}/signup`,
}

if (stagingPrefix.startsWith("http")) {
	// assume in this case that it's a redirect to the old app on express
	// handle some special routes that don't map directly to the new routes
	/*
	staging.account.boards = `${stagingPrefix}/boards`
	staging.account.contacts = `${stagingPrefix}/contacts`
	staging.account.feedback = `${stagingPrefix}/beta`
	staging.account.index = `${stagingPrefix}/boards`
	staging.account.settings = `${stagingPrefix}/settings`;
	*/
	// staging.new_board = `${stagingPrefix}/board`;
	// staging.demo = `${stagingPrefix}/board`;
}

export default {
	...staging,

	// non-staging
	account: {
		boards: `/account/boards/`,
		contacts: `/account/contacts/`,
		feedback: `/account/feedback/`,
		index: `/account/`,
		settings: `/account/settings/`,
	},
	forms: {
		beta: "/forms/beta/",
		discount: "/forms/discount/",
	},
	board: (id: string) => `/board/?id=${id}`, // the trailing slash is super important -- the query string will be dropped otherwise
	confirm: `/confirm/`,
	contact: "/contact/",
	demo: "/demo/",
	faq: "/faq/",
	forgot_password: `/forgot_password/`,
	index: "/",
	login: `/login/`,
	logout: `/logout/`,
	new_board: "/new_board/",
	pricing: "/pricing/",
	privacy_policy: "/privacy_policy/",
	signup: `/signup/`,
	terms_of_service: "/terms_of_service/",

	// todo (how to handle?)
	auth_google: "/auth/google",
}



////////////////////////////////////////////////////////////////////////
// mock Link and navigate() for urls that aren't necessarily internal //
////////////////////////////////////////////////////////////////////////

import React from "react"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link = (props) => {
	const { children, to, activeClassName, partiallyActive, ...other } = props;
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export const navigate = (to, options: {replace?: boolean} = {}) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to);
	if (internal) {
		gatsbyNavigate(to, options);
	} else {
		if (options.replace) {
			window.location.href = to;
		} else {
			window.location.replace(to);
		}
	}
}
