
import { send, ApiResult } from "../lib/send"
import { apiBaseUrl } from "../environment"

const accountApiPath = `${apiBaseUrl}/account`;
const accountApiRoutes = {
	get_boards: { url: accountApiPath, method: "post", func: 'get_boards' },
	add_board: { url: accountApiPath, method: "post", func: 'add_board' },
	copy_board: { url: accountApiPath, method: "post", func: 'copy_board' },
	remove_board: { url: accountApiPath, method: "post", func: 'remove_board' },
};

export type BoardInfo = {
	urlId: string,
	name: string,
	updatedAt: string,
}

export async function get_boards(userToken: string): Promise<ApiResult<BoardInfo[]>> {
	return await send({ userToken }, accountApiRoutes.get_boards);
}

export async function add_board(userToken: string): Promise<ApiResult<BoardInfo>> {
	return await send({ userToken}, accountApiRoutes.add_board);
}

export async function copy_board(userToken: string, urlId: string): Promise<ApiResult<BoardInfo>> {
	return await send({ userToken, urlId }, accountApiRoutes.copy_board);
}

export async function remove_board(userToken: string, board: BoardInfo): Promise<ApiResult<{}>> {
	return await send({ userToken, urlId: board.urlId }, accountApiRoutes.remove_board);
}


export type JoinBoardInfo = {
	name: string,
	anyoneWithLinkCanEdit: boolean,
	boardToken: string,
	realtimeToken: string,
	stateFile: {
		getUrl: string,
		putUrl?: string, // only when user has write permissions
		expiresMillis: number,
	}
}

export async function join_board(userToken: string, boardUrlId: string): Promise<ApiResult<JoinBoardInfo>> {
	return await send({ userToken, urlId: boardUrlId }, { url: `${apiBaseUrl}/board`, method: "post", func: 'join_board' });
}

export async function set_board_name(boardToken: string, name: string) {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken, name }, { url: `${apiBaseUrl}/board`, method: "post", func: 'set_board_name' });
}

export async function set_link_edit(boardToken: string, edit: boolean) {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken, edit }, { url: `${apiBaseUrl}/board`, method: "post", func: 'set_link_edit' });
}
